export const NewsletterManager = (() => {
    const initNewsletterForms = () => {
      const newsletterForms = document.querySelectorAll('form[data-type="newsletter-form"]');

      const setFormState = (form, isDisabled) => {
        const formFields = form.querySelectorAll('input,button'),
              formButton = form.querySelector('button[type=submit]');

        formFields.forEach(el => el.disabled = isDisabled);
        formButton.disabled = isDisabled;

        formButton.classList[isDisabled ? 'add' : 'remove']('btn-loading');
      };

      newsletterForms.forEach(form => form.addEventListener('submit', e => {
        e.preventDefault();
        e.stopPropagation();

        const formData = new FormData(form),
              abortController = new AbortController(),
              fetchTimeout = setTimeout(() => abortController.abort(), 10000),
              errorMessage = 'ERROR:\nSe ha producido un error al suscribirte a nuestra newsletter.\nPor favor, inténtalo de nuevo y ponte en contacto con nosotros si el error persiste.';

        setFormState(form, true);

        fetch(`${REMOLONAS_API_BASE_URL}newsletter/sign-up`, {
            method: 'post',
            body: formData,
            cache: 'no-store',
            signal: abortController.signal,
            headers: {'Accept': 'application/json'},
        }).then(response => {
            clearTimeout(fetchTimeout);

            return response.ok ? response.json() : response.json().then(err => Promise.reject(err));
        }).then(data => {
          if (data.data) {
            alert('¡Te has suscrito correctamente!');
            form.querySelectorAll('input').forEach(el => el.value = '');
          } else {
            alert(data.message || errorMessage);
          }
        }).catch(error => {
          if (error.name == 'AbortError') {
            alert('ERROR:\nSe ha agotado el tiempo de espera.\nPor favor, inténtalo de nuevo.');
          } else {
            // console.log(error.message);
            alert(errorMessage);
          }
        }).finally(() => {
          setFormState(form, false);
        });
      }));
    };

    return {
      initNewsletterForms
    };
  })();